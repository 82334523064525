import React, { useState, useCallback } from "react";
import { Box, useTheme, Tab, Tabs, Container, Typography, TextField, Grid, Divider, Button, MenuItem, IconButton, Rating } from "@mui/material";
import Iconify from "components/iconify";
import Header from "components/Header";

const TerceroEditar = () => {
  const theme = useTheme();
  const [currentTab, setCurrentTab] = useState("general");
  const [bankAccounts, setBankAccounts] = useState([{ bank: "", account: "", accountType: "" }]);
  const [wallets, setWallets] = useState([{ wallet: "", value: "" }]);
  const [generalInfo, setGeneralInfo] = useState({
    doi: "",
    nombre: "",
    direccion: "",
    ciudad: "",
    telefono: "",
    edad: "",
    negocio: "",
    ingresos: "",
    activos: "",
    zona: ""
  });
  const [ratingInfo, setRatingInfo] = useState({
    rating: 0,
    prestamos: "",
    mesesRetraso: "",
    maxPrestamo: "",
    promedioPrestamo: "",
    comentariosPositivos: "",
    comentariosNegativos: ""
  });

  const handleChangeTab = useCallback(function (event, newValue) {
    setCurrentTab(newValue);
  }, []);

  const handleAddBankAccount = () => {
    setBankAccounts([...bankAccounts, { bank: "", account: "", accountType: "" }]);
  };

  const handleRemoveBankAccount = (index) => {
    const newBankAccounts = bankAccounts.filter((_, i) => i !== index);
    setBankAccounts(newBankAccounts);
  };

  const handleBankAccountChange = (index, field, value) => {
    const newBankAccounts = [...bankAccounts];
    newBankAccounts[index][field] = value;
    setBankAccounts(newBankAccounts);
  };

  const handleAddWallet = () => {
    setWallets([...wallets, { wallet: "", value: "" }]);
  };

  const handleRemoveWallet = (index) => {
    const newWallets = wallets.filter((_, i) => i !== index);
    setWallets(newWallets);
  };

  const handleWalletChange = (index, field, value) => {
    const newWallets = [...wallets];
    newWallets[index][field] = value;
    setWallets(newWallets);
  };

  const handleGeneralInfoChange = (field, value) => {
    setGeneralInfo({ ...generalInfo, [field]: value });
  };

  const handleRatingInfoChange = (field, value) => {
    setRatingInfo({ ...ratingInfo, [field]: value });
  };

  const handleSaveGeneral = () => {
    console.log("Datos generales guardados:", generalInfo);
  };

  const handleSave = () => {
    const allBankAccounts = bankAccounts.map(account => ({
      bank: account.bank,
      account: account.account,
      accountType: account.accountType
    }));

    const allWallets = wallets.map(wallet => ({
      wallet: wallet.wallet,
      value: wallet.value
    }));

    const allData = {
      bankAccounts: allBankAccounts,
      wallets: allWallets
    };

    console.log("Datos guardados:", allData);
  };

  const TABS = [
    {
      value: "general",
      label: "General",
      icon: <Iconify icon="solar:user-id-bold" width={24} />,
      content: (
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="DOI"
                variant="outlined"
                value={generalInfo.doi}
                onChange={(e) => handleGeneralInfoChange("doi", e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Nombre del cliente"
                variant="outlined"
                value={generalInfo.nombre}
                onChange={(e) => handleGeneralInfoChange("nombre", e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Dirección"
                variant="outlined"
                value={generalInfo.direccion}
                onChange={(e) => handleGeneralInfoChange("direccion", e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Ciudad"
                variant="outlined"
                value={generalInfo.ciudad}
                onChange={(e) => handleGeneralInfoChange("ciudad", e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Teléfono"
                variant="outlined"
                value={generalInfo.telefono}
                onChange={(e) => handleGeneralInfoChange("telefono", e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Edad"
                variant="outlined"
                value={generalInfo.edad}
                onChange={(e) => handleGeneralInfoChange("edad", e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Negocio"
                variant="outlined"
                value={generalInfo.negocio}
                onChange={(e) => handleGeneralInfoChange("negocio", e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Ingresos"
                variant="outlined"
                value={generalInfo.ingresos}
                onChange={(e) => handleGeneralInfoChange("ingresos", e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Activos"
                variant="outlined"
                value={generalInfo.activos}
                onChange={(e) => handleGeneralInfoChange("activos", e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Zona"
                variant="outlined"
                value={generalInfo.zona}
                onChange={(e) => handleGeneralInfoChange("zona", e.target.value)}
              />
            </Grid>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveGeneral}
            fullWidth
            startIcon={<Iconify icon="mdi:content-save" />}
            sx={{ mt: 2 }}
          >
            GUARDAR
          </Button>
        </Box>
      ),
    },
    {
      value: "cuenta",
      label: "Cuenta",
      icon: <Iconify icon="solar:bill-list-bold" width={24} />,
      content: (
        <Box>
          <Typography variant="h6">Cuentas Bancarias</Typography>
          {bankAccounts.map((account, index) => (
            <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
              <Grid item xs={4}>
                <TextField
                  select
                  fullWidth
                  label="Banco"
                  value={account.bank}
                  onChange={(e) => handleBankAccountChange(index, "bank", e.target.value)}
                >
                  {["IBK", "BBVA", "BCP", "Nación", "ScotiaBank"].map((bank) => (
                    <MenuItem key={bank} value={bank}>
                      {bank}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="La cuenta"
                  value={account.account}
                  onChange={(e) => handleBankAccountChange(index, "account", e.target.value)}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  select
                  fullWidth
                  label="Tipo de cuenta"
                  value={account.accountType}
                  onChange={(e) => handleBankAccountChange(index, "accountType", e.target.value)}
                >
                  {["Cuenta corriente", "Cuenta de ahorros"].map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={1}>
                <IconButton onClick={() => handleRemoveBankAccount(index)}>
                  <Iconify icon="mdi:delete" />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Button variant="contained" onClick={handleAddBankAccount} sx={{ mt: 2 }}>
            Agregar Cuenta Bancaria
          </Button>
          <Divider sx={{ my: 3 }} />
          <Typography variant="h6">Billeteras</Typography>
          {wallets.map((wallet, index) => (
            <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
              <Grid item xs={5}>
                <TextField
                  select
                  fullWidth
                  label="Billetera"
                  value={wallet.wallet}
                  onChange={(e) => handleWalletChange(index, "wallet", e.target.value)}
                >
                  {["Yape", "PLIN"].map((walletOption) => (
                                    <MenuItem key={walletOption} value={walletOption}>
                                    {walletOption}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                            <Grid item xs={5}>
                              <TextField
                                fullWidth
                                label="Valor"
                                value={wallet.value}
                                onChange={(e) => handleWalletChange(index, "value", e.target.value)}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <IconButton onClick={() => handleRemoveWallet(index)}>
                                <Iconify icon="mdi:delete" />
                              </IconButton>
                            </Grid>
                          </Grid>
                        ))}
                        <Button variant="contained" onClick={handleAddWallet} sx={{ mt: 2 }}>
                          Agregar Billetera
                        </Button>
                        <Divider sx={{ my: 3 }} />
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSave}
                          fullWidth
                          startIcon={<Iconify icon="mdi:content-save" />}
                        >
                          GUARDAR
                        </Button>
                      </Box>
                    ),
                  },
                  {
                    value: "rating",
                    label: "Rating",
                    icon: <Iconify icon="solar:bell-bing-bold" width={24} />,
                    content: (
                      <Box>
                        <Typography variant="h6">Calificación del Cliente</Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Rating
                              name="rating"
                              value={ratingInfo.rating}
                              onChange={(event, newValue) => handleRatingInfoChange("rating", newValue)}
                              max={10}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              label="# de préstamos"
                              variant="outlined"
                              value={ratingInfo.prestamos}
                              onChange={(e) => handleRatingInfoChange("prestamos", e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              label="# de meses con retraso"
                              variant="outlined"
                              value={ratingInfo.mesesRetraso}
                              onChange={(e) => handleRatingInfoChange("mesesRetraso", e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              label="Monto máximo de préstamos"
                              variant="outlined"
                              value={ratingInfo.maxPrestamo}
                              onChange={(e) => handleRatingInfoChange("maxPrestamo", e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              fullWidth
                              label="Monto promedio de préstamos"
                              variant="outlined"
                              value={ratingInfo.promedioPrestamo}
                              onChange={(e) => handleRatingInfoChange("promedioPrestamo", e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              label="Comentarios positivos"
                              variant="outlined"
                              multiline
                              rows={4}
                              value={ratingInfo.comentariosPositivos}
                              onChange={(e) => handleRatingInfoChange("comentariosPositivos", e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              label="Comentarios negativos"
                              variant="outlined"
                              multiline
                              rows={4}
                              value={ratingInfo.comentariosNegativos}
                              onChange={(e) => handleRatingInfoChange("comentariosNegativos", e.target.value)}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    ),
                  },
                  {
                    value: "security",
                    label: "Security",
                    icon: <Iconify icon="ic:round-vpn-key" width={24} />,
                    content: (
                      <Typography variant="body1">
                        Configuraciones de seguridad, incluyendo contraseñas, autenticación de dos factores y preguntas de seguridad.
                      </Typography>
                    ),
                  },
                ];
              
                return (
                  <Box m="1.5rem 2.5rem">
                    <Header title="CLIENTES" subtitle="Editar Cliente" />
                    <br></br>
                    <Box height="80vh" sx={[{ maxWidth: "850px", margin: "0 auto" }]}>
                      <Tabs
                        value={currentTab}
                        onChange={handleChangeTab}
                        sx={{
                          mb: { xs: 3, md: 5 },
                        }}
                      >
                        {TABS.map((tab) => (
                          <Tab
                            key={tab.value}
                            label={tab.label}
                            icon={tab.icon}
                            value={tab.value}
                            iconPosition="start"
                          />
                        ))}
                      </Tabs>
                      <Box mt={3}>
                        {TABS.map((tab) => (
                          <Box
                            key={tab.value}
                            role="tabpanel"
                            hidden={currentTab !== tab.value}
                          >
                            {currentTab === tab.value && tab.content}
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                );
              };
              
              export default TerceroEditar;
              