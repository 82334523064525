import React, { useState, useEffect, createRef } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { format } from "date-fns";
import Header from "components/Header";
import InsertIcon from "@mui/icons-material/FileCopy";
import SearchIcon from "@mui/icons-material/Search";
import {
  useGetParametrosQuery,
  useGetDOIexQuery,
  useSolicitudInsertarTMutation,
  useGetTerceroDOIQuery, 
} from "state/api";
import {
  Box,
  Button,
  TextField,
  Snackbar,
  Slide, 
  Alert,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  LinearProgress,
  InputAdornment, 
  Stack,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";

const Solicitud = () => {
  const schema = yup.object().shape({
    DOI: yup.string().required("El DOI es obligatorio"),
    Nombres: yup.string().required("El Nombre es obligatorio"),
    ApePat: yup.string().required("El Apellido Paterno es obligatorio"),
    ApeMat: yup.string().required("El Apellido Materno es obligatorio"),
    MtoSol: yup
      .number()
      .typeError("El Monto debe ser un número")
      .moreThan(0, "El Monto debe ser mayor a 0.00"),
  });
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const values = watch();
  const [success, setSuccess] = useState(false);  
  const { data: solicitudData } = useGetParametrosQuery({
    Campo1: "Etiqueta",
    Valor1: "SOLICITUD",
    Campo2: "IdPadre",
    Valor2: "12",
  });

  const { data: evaluacionData } = useGetParametrosQuery({
    Campo1: "Etiqueta",
    Valor1: "EVALUACION",
    Campo2: "IdPadre",
    Valor2: "12",
  });

  const { data: productosData } = useGetParametrosQuery({
    Campo1: "Etiqueta",
    Valor1: "PRODUCTOS",
    Campo2: "IdPadre",
    Valor2: "2",
  });

  const { data: monedaData } = useGetParametrosQuery({
    Campo1: "Etiqueta",
    Valor1: "MONEDA",
    Campo2: "IdPadre",
    Valor2: "12",
  });

  const { data: motivoData } = useGetParametrosQuery({
    Campo1: "Etiqueta",
    Valor1: "MOTIVO",
    Campo2: "IdPadre",
    Valor2: "12",
  });
  const [datos, setDatos] = useState({
    Nombres: "", // Valor inicial vacío
    ApePat: "",
    ApeMat: "",
  });

  useEffect(() => {
    if (solicitudData && solicitudData.length > 0) {
      const tipoSol = solicitudData.find(
        (parametro) => parametro.Valor === "SNUE"
      ).Valor;
      setValue("TipoSol", tipoSol);
    }

    if (evaluacionData && evaluacionData.length > 0) {
      const tipoEval = evaluacionData.find(
        (parametro) => parametro.Valor === "EPMU"
      ).Valor;
      setValue("TipoEval", tipoEval);
    }

    if (productosData && productosData.length > 0) {
      const tipoProd = productosData.find(
        (parametro) => parametro.Valor === "CPER"
      ).Valor;
      setValue("TipoProd", tipoProd);
    }

    if (monedaData && monedaData.length > 0) {
      const tipoMoneda = monedaData.find(
        (parametro) => parametro.Valor === "PEN"
      ).Valor;
      setValue("TipoMoneda", tipoMoneda);
    }

    if (motivoData && motivoData.length > 0) {
      const tipoMot = motivoData.find(
        (parametro) => parametro.Valor === "EVO"
      ).Valor;
      setValue("TipoMot", tipoMot);
    }
  }, [
    solicitudData,
    evaluacionData,
    productosData,
    monedaData,
    motivoData,
    setValue,
  ]);

  useEffect(() => {
    if (
      solicitudData &&
      solicitudData.length > 0 &&
      evaluacionData &&
      evaluacionData.length > 0 &&
      productosData &&
      productosData.length > 0 &&
      monedaData &&
      monedaData.length > 0 &&
      motivoData &&
      motivoData.length > 0
    ) {
      // Todos los hooks han sido cargados, realizar acciones adicionales aquí
      console.log("Todos los hooks han sido cargados");
    }
  }, [solicitudData, evaluacionData, productosData, monedaData, motivoData]);

  const [DOI, setDOI] = useState("");
  const [shouldFetchDOIex, setShouldFetchDOIex] = useState(false);
  const [shouldFetchTercero, setShouldFetchTercero] = useState(false);
  const { isLoading: isLoadingDOIex, error: errorDOIex, data: DNIData } = useGetDOIexQuery(
    { DOI },
    { skip: !shouldFetchDOIex }
  );

  const { isLoading: isLoadingTercero, error: errorTercero, data: TerceroData } = useGetTerceroDOIQuery(
    { DOI },
    { skip: !shouldFetchTercero }
  );

  const onSubmitBuscar = () => {
    try {
      if (!values.DOI) {
        console.error("El DOI es obligatorio para realizar la búsqueda.");
        return;
      }

      setDOI(values.DOI);

      if (values.TipoSol === "SNUE") {
        setShouldFetchDOIex(true);
        setShouldFetchTercero(false);
      } else {
        setShouldFetchDOIex(false);
        setShouldFetchTercero(true);
      }
    } catch (error) {
      console.error("Error al realizar la búsqueda:", error);
    }
  };

  useEffect(() => {
    if (!isLoadingDOIex && !errorDOIex && DNIData) {
      setDatos((prevDatos) => ({
        ...prevDatos,
        Nombres: DNIData.nombres,
        ApePat: DNIData.apellidoPaterno,
        ApeMat: DNIData.apellidoMaterno,
      }));
      setValue("Nombres", DNIData.nombres);
      setValue("ApePat", DNIData.apellidoPaterno);
      setValue("ApeMat", DNIData.apellidoMaterno);
      setShouldFetchDOIex(false); // Reset para evitar llamadas innecesarias
    } else if (!isLoadingTercero && !errorTercero && TerceroData) {
      setDatos((prevDatos) => ({
        ...prevDatos,
        Nombres: TerceroData[0].Nombres,
        ApePat: TerceroData[0].ApePat,
        ApeMat: TerceroData[0].ApeMat,
      }));
      setValue("Nombres", TerceroData[0].Nombres);
      setValue("ApePat", TerceroData[0].ApePat);
      setValue("ApeMat", TerceroData[0].ApeMat);
      setShouldFetchTercero(false); // Reset para evitar llamadas innecesarias
    }
  }, [isLoadingDOIex, errorDOIex, DNIData, isLoadingTercero, errorTercero, TerceroData]);


  const [solicitudInsertarMutation] = useSolicitudInsertarTMutation();
 
  const onSubmit = (data) => {    
    setSuccess(false);
    const fecha = new Date();
    const newData = {
      ...data, // Propagamos los datos del formulario
      TipoDoc:"DNI",
      Estado:"ACT",
      FecSol: format(fecha, "yyyy-MM-dd HH:mm:ss.SSS"),
      Canal: "WEB",
      UsMod: 1,
    };
    console.log(newData);

    solicitudInsertarMutation(newData)
    .unwrap()
    .then((data) => {
      setSuccess(true);
      console.log("La solicitud se insertó correctamente", data);
    })
    .catch((error) => {     
      console.log("La solicitud falló", error.data.error);
    });

  };
  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
  };

  const snackbarRef = createRef();
  return (
    <Box height="80vh" sx={[{ maxWidth: "850px", margin: "0 auto" }]}>
      <Box m="1.5rem 2.5rem">
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Header title="SOLICITUDES" subtitle="Nuevas solicitudes" />
          </Box>
          <Box sx={{ flexShrink: 0 }}>
            <Button variant="contained" startIcon={<InsertIcon />}>
              Nueva Solicitud
            </Button>
          </Box>
        </Box>
        <br></br>

        <Box height="80vh" sx={[{ maxWidth: "850px", margin: "0 auto" }]}>
          {solicitudData &&
          evaluacionData &&
          productosData &&
          monedaData &&
          motivoData ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl variant="filled" fullWidth>
                <InputLabel id="tipoSolicitud-label">
                  Tipo de solicitud
                </InputLabel>
                <Select
                  value={values.TipoSol || ""}
                  name="TipoSol"
                  labelId="tipoSolicitud-label"
                  {...register("TipoSol")}
                  sx={{ mb: 2 }}
                >
                  {solicitudData && solicitudData.length > 0 ? (
                    solicitudData.map((parametro) => (
                      <MenuItem
                        key={parametro.IdParametro}
                        value={parametro.Valor}
                      >
                        {parametro.Descripcion}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">Cargando...</MenuItem>
                  )}
                </Select>
              </FormControl>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <TextField
                  error={Boolean(errors.DOI)}
                  label="DOI"
                  name="DOI"
                  fullWidth
                  sx={{ mb: 2, marginRight: 1 }}
                  {...register("DOI")}
                  helperText={errors.DOI && errors.DOI.message}
                />

                <Button
                  sx={{ mb: 2, height: "100%", minWidth: "auto" }}
                  variant="contained"
                  color="primary"
                  startIcon={<SearchIcon />}
                  onClick={onSubmitBuscar}
                >
                  Buscar
                </Button>
              </Box>
              <TextField
                error={Boolean(errors.Nombres)}                
                InputProps={{
                  startAdornment: <InputAdornment position="start">Nombres: </InputAdornment>,
                }}       
                name="Nombres: "                
                fullWidth
                sx={{ mb: 2 }}
                {...register("Nombres")}
                helperText={errors.Nombres && errors.Nombres.message}
              />

              <TextField
                error={Boolean(errors.ApePat)}
                InputProps={{
                  startAdornment: <InputAdornment position="start">Apellido Paterno: </InputAdornment>,
                }}                                    
                name="ApePat"
                fullWidth
                sx={{ mb: 2 }}
                {...register("ApePat")}
                helperText={errors.ApePat && errors.ApePat.message}
              />

              <TextField
                error={Boolean(errors.ApeMat)}
                InputProps={{
                  startAdornment: <InputAdornment position="start">Apellido Materno: </InputAdornment>,
                }}                                  
                name="ApeMat"
                fullWidth
                sx={{ mb: 2 }}
                {...register("ApeMat")}
                helperText={errors.ApeMat && errors.ApeMat.message}
              />
              <FormControl fullWidth variant="filled" sx={{ mb: 2 }}>
                <InputLabel id="tipoEval-label">Tipo de evaluación</InputLabel>
                <Select
                  value={values.TipoEval || ""}
                  name="TipoEval"
                  labelId="tipoEval-label"
                  {...register("TipoEval")}
                >
                  {evaluacionData && evaluacionData.length > 0 ? (
                    evaluacionData.map((parametro) => (
                      <MenuItem
                        key={parametro.IdParametro}
                        value={parametro.Valor}
                      >
                        {parametro.Descripcion}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">Cargando...</MenuItem>
                  )}
                </Select>
              </FormControl>
              <FormControl fullWidth variant="filled" sx={{ mb: 2 }}>
                <InputLabel id="tipoProd-label">Tipo de producto</InputLabel>
                <Select
                  value={values.TipoProd || ""}
                  name="TipoProd"
                  labelId="tipoProd-label"
                  {...register("TipoProd")}
                >
                  {productosData && productosData.length > 0 ? (
                    productosData.map((parametro) => (
                      <MenuItem
                        key={parametro.IdParametro}
                        value={parametro.Valor}
                      >
                        {parametro.Descripcion}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">Cargando...</MenuItem>
                  )}
                </Select>
              </FormControl>
              <FormControl variant="filled" fullWidth sx={{ mb: 2 }}>
                <InputLabel id="tipoMoneda-label">Tipo de moneda</InputLabel>
                <Select
                  value={values.TipoMoneda || ""}
                  name="TipoMoneda"
                  labelId="tipoMoneda-label"
                  {...register("TipoMoneda")}
                >
                  {monedaData && monedaData.length > 0 ? (
                    monedaData.map((parametro) => (
                      <MenuItem
                        key={parametro.IdParametro}
                        value={parametro.Valor}
                      >
                        {parametro.Descripcion}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">Cargando...</MenuItem>
                  )}
                </Select>
              </FormControl>
              <TextField
                error={Boolean(errors.MtoSol)}
                label="Monto solicitado"
                name="MtoSol"
                fullWidth
                sx={{ mb: 2 }}
                {...register("MtoSol")}
                helperText={errors.MtoSol && errors.MtoSol.message}
              />
              <FormControl variant="filled" fullWidth sx={{ mb: 2 }}>
                <InputLabel id="tipoMotivo-label">Motivo</InputLabel>
                <Select
                  value={values.TipoMot || ""}
                  name="TipoMot"
                  labelId="tipoMotivo-label"
                  {...register("TipoMot")}
                >
                  {motivoData && motivoData.length > 0 ? (
                    motivoData.map((parametro) => (
                      <MenuItem
                        key={parametro.IdParametro}
                        value={parametro.Valor}
                      >
                        {parametro.Descripcion}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">Cargando...</MenuItem>
                  )}
                </Select>
              </FormControl>
              <Box
                sx={{
                  display: values.TipoEval === "EREF" ? "block" : "none",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    label="DOI del referido (opcional)"
                    name="DOIRef"
                    fullWidth
                    sx={{ mb: 2, minWidth: "auto", marginRight: 1 }}
                    {...register("DOIRef")}
                  />

                  <Button
                    sx={{
                      mb: 2,
                      height: "100%",
                      marginRight: 1,
                      minWidth: "auto",
                    }}
                    variant="contained"
                    color="primary"
                    startIcon={<SearchIcon />}
                  >
                    Validar
                  </Button>
                </Box>
              </Box>
              <Button
                sx={{ mt: 2 }}
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                startIcon={<SaveIcon />}
              >
                Guardar
              </Button>
            </form>
          ) : (
            <Stack sx={{ width: "100%", color: "grey.500" }} spacing={2}>
              <LinearProgress color="secondary" />
            </Stack>
          )}

          <Snackbar
            TransitionComponent={(props) => (
              <Slide ref={snackbarRef} {...props} direction="down" />
            )}
            open={success}
            autoHideDuration={6000}
            onClose={handleClose}
            sx={{ mt: 2 }}
          >
            <Alert onClose={handleClose} severity="success">
              Solicitud enviada con éxito
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    </Box>
  );
};

export default Solicitud;
