import React, { useState } from "react";
import { Box, useTheme, TextField, Button } from "@mui/material";
import { useGetTerceroQuery } from "state/api";
import Header from "components/Header";
import { DataGrid } from "@mui/x-data-grid";

const Tercero = () => {
  const theme = useTheme();
  const [searchDOI, setSearchDOI] = useState("");
  
  const { data, isLoading } = useGetTerceroQuery();

  const columns = [
    {
      field: "DOI",
      headerName: "DOI",
      flex: 0.2,
    },
    {
      field: "Nombres",
      headerName: "Nombres",
      flex: 0.4,
    },
    {
      field: "ApePat",
      headerName: "Apellido Paterno",
      flex: 0.3,
    },
    {
      field: "ApeMat",
      headerName: "Apellido Materno",
      flex: 0.3,
    },
    {
      field: "Estado",
      headerName: "Estado",
      flex: 0.5,
    },
    {
      field: "NumTel",
      headerName: "Número Teléfono",
      flex: 0.5,
      renderCell: (params) => {
        return params.value ? params.value.replace(/^(\d{3})(\d{3})(\d{4})/, "($1)$2-$3") : "";
      },
    },
    {
      field: "Ocupacion",
      headerName: "Ocupación",
      flex: 0.4,
    },
    {
      field: "actions",
      headerName: "Acciones",
      flex: 0.2,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="info"
          onClick={() => handleVerClick(params.row.DOI)}
        >
          VER
        </Button>
      ),
    },
  ];

  const handleVerClick = (doi) => {
    // Aquí puedes redirigir a la página de detalle con el DOI como parámetro
    console.log(`Ver detalles del cliente con DOI: ${doi}`);
    // Implementa la lógica para redirigir a la página de detalle según tus necesidades
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="CLIENTES" subtitle="Lista de Clientes" />
      <Box mt="40px" height="75vh">
        <TextField
          fullWidth
          label="Buscar por DOI"
          variant="outlined"
          value={searchDOI}
          onChange={(e) => setSearchDOI(e.target.value)}
          sx={{ mb: 2 }}
        />
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row.DOI}
          rows={data || []}
          columns={columns}
        />
      </Box>
    </Box>
  );
};

export default Tercero;
